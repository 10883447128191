<div class="form-group" [formGroup]="group">
	<mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic" class="form-input w-100">
		<mat-label>{{ field.label | localize }}</mat-label>
		<mat-icon [loadingIcon]="isSearching" fontIconLoading="domain" matPrefix color="primary"></mat-icon>
		<input
			matInput
			[formControlName]="field.name"
			[matAutocomplete]="autocomplete"
			placeholder="{{ field.label | localize }}"
			spellcheck="false"
			autocomplete="off"
			autocorrect="off"
			autocapitalize="off"
			(focusin)="onFocusIn()"
		/>
		<mat-autocomplete #autocomplete="matAutocomplete" (optionSelected)="onChange($event)" [displayWith]="displayOption">
			<mat-option class="autocomplete-option" *ngFor="let x of filteredOptions$ | async" [value]="x" [disabled]="x.key == null">
				{{ x.value | localize }}
			</mat-option>
		</mat-autocomplete>

		<button
              *ngIf="group.controls[field.name].value?.key && !readonly"
              matSuffix
              mat-icon-button
              aria-label="Clear"
			  [disabled]="group.controls[field.name].disabled"
              (click)="clearInput()"
            >
              <mat-icon>close</mat-icon>
		</button>
	</mat-form-field>
	<app-error-validation-messages [group]="group" [field]="field"></app-error-validation-messages>
</div>
