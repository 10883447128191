import { Component, ElementRef, Inject, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatFormField } from '@angular/material/form-field';
import { ActivatedRoute } from '@angular/router';
import { AjaxResponseOfIEnumerableOfLocationDto, DestinationTypeDto, LocationDto, TripItemTypeDto } from '@app/api/models';
import { CarService, FlightService, HotelService, TrainService } from '@app/api/services';
import { RiskCountryConfigInput } from '@app/shared/models/risk-country-config-input';
import { TRIP_ITEM_TYPE } from '@app/shared/services/trip/trip-history.token';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Observable, startWith, debounceTime, filter, distinctUntilChanged, switchMap, map, finalize, of, forkJoin, catchError, take, tap } from 'rxjs';

@Component({
	selector: 'app-location-autocomplete',
	templateUrl: './location-autocomplete.component.html',
	styleUrls: ['./location-autocomplete.component.scss'],
})
export class LocationAutocompleteComponent extends AppComponentBase implements OnInit {
	@Input() form: FormControl;
	@Input() placeholder: string;
	@Input() controlName: string;
	@Input() riskCountryConfigInput: RiskCountryConfigInput;
	@Input() autoSelectOnFocusOut: boolean = false;

	@ViewChild('auto') autocomplete: MatAutocomplete;

	tripItemType: TripItemTypeDto;
	filteredOptions: Observable<LocationDto[]>;
	startLoading: boolean;
	fontIcon: string;
	readonly debounce: number = 400;


	constructor(
		injector: Injector,
		@Inject(TRIP_ITEM_TYPE) tripItemType: TripItemTypeDto,
		private hotelService: HotelService,
		private trainService: TrainService,
		private flightService: FlightService,
		private carService: CarService,
		private _activatedRoute: ActivatedRoute
	) {
		super(injector);
		this.tripItemType = tripItemType;
	}

	ngOnInit(): void {
		this.fontIcon = TripItemTypeDto[this.tripItemType].toLowerCase();

		this.filteredOptions = this.form.get(this.controlName).valueChanges.pipe(
			startWith(''),
			debounceTime(this.debounce),
			filter((t) => typeof t === 'string' && t.length >= 2),
			distinctUntilChanged(),
			switchMap((val: string) => {
				return this._filter(val || '');
			}),
			tap((locations) => {				
				// if (this.autoSelectOnFocusOut && !this.autocomplete.isOpen && typeof this.form.get(this.controlName).value === 'string') {
				// 	this.focusLost(locations);
				// }
			})
		);
	}

	focusLost(locations: LocationDto[]) {
		const refForm = this.form.get(this.controlName);
		if (!refForm.value) {
			return;
		}
		if (!locations.some(o => o=== refForm.value)) {
			refForm.setValue(locations[0], {emitEvent: false});
		}
	}

	displayFn(user: LocationDto): string {
		return user && user.displayName ? user.displayName : '';
	}

	private _filter(value: string): Observable<LocationDto[]> {
		this.startLoading = true;

		let searchApi: Observable<AjaxResponseOfIEnumerableOfLocationDto> = of(null);
		switch (this.tripItemType) {
			case TripItemTypeDto.Train:
				searchApi = this.trainService.getDestinationAutocomplete_2({
					query: value,
					isCarnet: false,
				});
				break;
			case TripItemTypeDto.Flight:
				searchApi = this.flightService.getDestinationAutocomplete({
					query: value,
				});
				break;
			case TripItemTypeDto.Hotel:
				if (this.form.get('destinationInCache').value) {
					const availabilityId = this._activatedRoute.snapshot.params['availabilityId'];
					searchApi = this.hotelService
						.getDestinationAutocomplete_1({ query: value, searchRegionRoot: false, withinDestinId: this.form.get('destinationIds').value[0], availabilityId: availabilityId })
						.pipe(
							map((t) => {
								const tmp = <AjaxResponseOfIEnumerableOfLocationDto>{
									success: t.success,
									result: t.result.map(
										(tx) =>
											<LocationDto>{
												cityName: tx.description,
												displayName: tx.description,
												code: tx.value['id'],
												latitude: tx.value['latitude'],
												longitude: tx.value['longitude'],
												countryCode: tx.isoCountry,
												isMultiCity: tx.type == DestinationTypeDto.City,
											}
									),
								};

								return tmp;
							})
						);
				} else {
					searchApi = this.hotelService.getDestinationAutocomplete_1({ query: value, searchRegionRoot: true }).pipe(
						map((t) => {
							const tmp = <AjaxResponseOfIEnumerableOfLocationDto>{
								success: t.success,
								result: t.result.map(
									(tx) =>
										<LocationDto>{
											cityName: tx.description,
											displayName: tx.description,
											code: tx.value['id'],
											latitude: tx.value['latitude'],
											longitude: tx.value['longitude'],
											countryCode: tx.isoCountry,
											isMultiCity: tx.type == DestinationTypeDto.City,
										}
								),
							};

							return tmp;
						})
					);
				}
				break;
			case TripItemTypeDto.Car:
				searchApi = this.carService.getLocationAutocomplete({
					text: value,
				});
				break;
			case TripItemTypeDto.PackageRate:
				searchApi = this.flightService.getDestinationAutocomplete({
					query: value,
					isPackageRateDestination: true,
				});
				break;
			default:
				throw 'Non implementato';
		}

		return searchApi.pipe(
			filter((r) => r.success),
			map((r) => r.result),
			catchError(() => of([])),
			finalize(() => (this.startLoading = false))
		);
	}
}
