import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { PersonNameDataDto, TripItemTypeDto } from '@app/api/models';
import { TravelItemAdditionalServiceType } from '@app/shared/models/travel-item-additional-service.model';
import { TravelItemAdditionalServiceInfoComponent } from './travel-item-additional-services-info/travel-item-additional-services-info.component';

@Component({
	selector: 'app-travel-item-additional-services',
	templateUrl: 'travel-item-additional-services.component.html',
	styleUrls: ['travel-item-additional-services.component.scss'],
	standalone: true,
	imports: [MatIconModule, CommonModule, TravelItemAdditionalServiceInfoComponent],
})
export class TravelItemAdditionalServicesComponent implements OnInit {
	@Input() tripItemType: TripItemTypeDto;

	_passengers: PersonNameDataDto[];
	@Input()
	set passengers(value: PersonNameDataDto[] | null) {
		if (value && value.length) {
			this._passengers = value;
		} else {
			this._passengers = null;
		}

		this.checkIconVisibility();
	}

	@Input() isOnMobile: boolean;

	isSeatBtnOn: boolean;
	isExtraOffersBtnOn: boolean;
	isExtraBaggageBtnOn: boolean;
	isSportEquipmentsBtnOn: boolean;
	travelItemAdditionalServiceType = TravelItemAdditionalServiceType;

	constructor(public dialog: MatDialog) {}

	ngOnInit(): void {
		this.checkIconVisibility();
	}

	openDialog(type: TravelItemAdditionalServiceType): void {
		this.dialog.open(TravelItemAdditionalServiceInfoComponent, {
			width: '350px',
			data: { tripItemType: this.tripItemType, serviceType: type, passengers: this._passengers },
		});
	}

	private checkIconVisibility()
	{
		this.isSeatBtnOn = this._passengers?.some(p => p.seats?.length > 0);
		this.isExtraOffersBtnOn = this._passengers?.some(p => p.extraOffers?.length > 0);
		this.isExtraBaggageBtnOn = this._passengers?.some(p => p.extraBags?.length > 0);
		this.isSportEquipmentsBtnOn = this._passengers?.some(p => p.sportEquipments?.length > 0);
	}
}
