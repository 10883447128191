<ng-container [formGroup]="form">
	<mat-form-field class="auto-form-field" appearance="outline" floatLabel="always" subscriptSizing="dynamic">
		<mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
		<input type="text" [placeholder]="placeholder" matInput [formControlName]="controlName" [matAutocomplete]="auto" />
		<mat-icon [loadingIcon]="startLoading" fontIconLoading="location_on" matPrefix color="primary"></mat-icon>
		
		<span *ngIf="riskCountryConfigInput" [formGroup]="form" matSuffix app-risk-country-informations [riskCountryConfigInput]="riskCountryConfigInput" class="mr-2"></span>
		
		<mat-autocomplete requireSelection #auto="matAutocomplete" [displayWith]="displayFn">
			<mat-option *ngFor="let option of filteredOptions | async" [value]="option">
				<mat-icon [fontIcon]="fontIcon" color="primary" *ngIf="!option.isMultiCity; else isMultiCity"></mat-icon>
				<ng-template #isMultiCity>
					<mat-icon fontIcon="location_city" color="primary"></mat-icon>
				</ng-template>
				<ng-container *ngIf="option.cityName">
					<b>{{ option.cityName }}</b>
					<br />
				</ng-container>
				{{ option.displayName }}
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>
</ng-container>
