import { Component, OnInit, Injector } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { _MatAutocompleteBase, MatAutocompleteModule } from '@angular/material/autocomplete';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@shared/utils/utils.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { debounceTime, finalize, map, Observable, of, switchMap } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { LoadingIconModule } from '@app/shared/directives/loading-icon/loading-icon.module';
import { FieldConfigService } from '@app/api/services';
import { OptionValueDto } from '@app/api/models';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { ErrorValidationMessagesComponent } from '../../error-validation-messages/error-validation-messages.component';
import { BaseAutocompleteComponent } from '../autocomplete-base.component';
@Component({
    selector: 'app-autocomplete-remote',
    templateUrl: './autocomplete-remote.component.html',
    styleUrls:  [
		'./../autocomplete-base.component.scss',
		'./autocomplete-remote.component.scss'
	],
    standalone: true,
    imports: [
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        CommonModule,
        UtilsModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        LoadingIconModule,
        MatDividerModule,
        ErrorValidationMessagesComponent
    ]
})
export class AutocompleteRemoteComponent extends BaseAutocompleteComponent implements OnInit {
    autocomplete$: Observable<OptionValueDto[]>;

    constructor(injector: Injector, private service: FieldConfigService) {
		super(injector);
	}

    ngOnInit() {
        super.ngOnInit();

		this.autocomplete$ = this.group.controls[this.field.name].valueChanges.pipe(
			debounceTime(500),
			switchMap((t: unknown | null) => {
                if (!t || (typeof t === 'string' && t.length <= 2)) {
                    return of([]);
                }

                if(typeof t === 'string' && t.length > 2) {
                    this.isSearching = true;
                    const parentVal = !!this.field.parentId ? this.group.controls[this.field.parentId]?.value : null;
                    return this.service.getFieldConfigOptionsDtoAutocomplete({ input: t, fieldName: this.field.name, parent: parentVal?.key, userProfiles: this.userProfiles?.map(x => x.userId) }).pipe(
                        map((data) =>  {
                            let retVal = this.noOptionsResponse();
                            if (data?.result?.length > 0) {
                                retVal = data.result
                            }

                            return retVal;
                        }),
                        finalize(() => {
                            this.isSearching = false;
                        })
                    );
                }

                return of([]);
			})
		);
	}
}
