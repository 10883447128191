import { OnInit, Input, Output, EventEmitter, Injector, Directive } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { WidgetComponentBaseComponent } from '@app/shared/common/customizable-dashboard/widgets/widget-component-base';
import { FieldConfigDto, OptionValueDto, UserProfileDto } from '@app/api/models';

@Directive()
export abstract class BaseAutocompleteComponent extends WidgetComponentBaseComponent implements OnInit {
	@Input() field: FieldConfigDto;
	@Input() group: UntypedFormGroup;
	@Input() readonly: boolean;
	@Input() userProfiles: Array<UserProfileDto>;
	@Output() onFieldChange: EventEmitter<unknown> = new EventEmitter<unknown>();
	
	
	protected isSearching: boolean;
	protected isParentSelected: boolean = false;

	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		if (this.field.parentId) {
			this.group.controls[this.field.parentId].valueChanges.subscribe((val) => {
				this.checkParent(val);
			});

			this.checkParent();
		}
	}

	triggerValueChange() {
		this.group.controls[this.field.name].updateValueAndValidity({ emitEvent: true });
	}

	noOptionsResponse(): OptionValueDto[] {
		return [{ key: null, value: 'Common_No_Result' }];
	}

	onChange(evt: MatAutocompleteSelectedEvent) {
		this.onFieldChange.emit(evt.option.value);
	}

	updateState() {
		const c = this.group.controls[this.field.name];
		const check = this.readonly || (this.field.parentId && !this.isParentSelected);
		if (check) {
			c.disable();
		} else {
			c.enable();
		}
	}

	displayOption(opt?: OptionValueDto) {
		if (opt) {
			return `${opt.value}`;
		}
		return '';
	}

	clearInput() {
		this.group.controls[this.field.name].setValue(null);
	}

	protected onParentSelected() {};

	protected onParentUnselected() {};

	private checkParent(value: unknown | null = null) {
		const parentVal = value ?? this.group.controls[this.field.parentId]?.value;
		const c = this.group.controls[this.field.name];

		if (parentVal && parentVal['key']) {
			this.isParentSelected = true;
			this.onParentSelected();
			if(!this.readonly) {
				this.clearInput();
			}
		} else {
			c.setValue(null);
			this.isParentSelected = false;
			this.onParentUnselected();
		}

		this.updateState();
	}
}
