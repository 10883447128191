<div class="container">
    <div class="row d-flex" [ngClass]="[isOnMobile? 'justify-content-start' : 'justify-content-end']">
        <ng-container *ngIf="isSeatBtnOn">
            <div class="col-2">
                <button type="button" class="btn btn-color" (click)="openDialog(travelItemAdditionalServiceType.Seat)">
                    <mat-icon>airline_seat_recline_normal</mat-icon>
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="isExtraBaggageBtnOn">
            <div class="col-2">
                <button type="button" class="btn btn-color" (click)="openDialog(travelItemAdditionalServiceType.Baggage)">
                    <mat-icon>luggage</mat-icon>
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="isSportEquipmentsBtnOn">
            <div class="col-2">
                <button type="button" class="btn btn-color" (click)="openDialog(travelItemAdditionalServiceType.Sports)">
                    <mat-icon>sports_soccer</mat-icon>
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="isExtraOffersBtnOn">
            <div class="col-2">
                <button type="button" class="btn btn-color" (click)="openDialog(travelItemAdditionalServiceType.ExtraOffers)">
                    <mat-icon>add_circle</mat-icon>
                </button>
            </div>
        </ng-container>
    </div>
</div>