<div [@routerTransition] *ngIf="!isMatDialog; else nakedForm">
    <div class="content d-flex flex-column flex-column-fluid">
        <div [class]="containerClass">
            <div class="card card-custom gutter-b">
                <div class="card-body">
                    <ng-container *ngTemplateOutlet="nakedForm"></ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #nakedForm>
    <form [formGroup]="formUserProfile" #formUserProfileTemplate="ngForm" novalidate autocomplete="off" *ngIf="formUserProfile">
        <div class="row col-12 d-inline-flex" *ngIf="permission.isGranted('Pages.UserProfile.Create') && showSaveUserProfileInput">
            <div *ngIf="showSaveUserProfileInput" class="mr-3">
                <mat-checkbox [(ngModel)]="saveUserProfile" (ngModelChange)="setFormEmailField()" [ngModelOptions]="{standalone: true}" color="primary">{{"UserProfile_Register" | localize}}</mat-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                <div class="form-group">
                    <label>{{'PersonNameDataDTO_GivenName' | localize}}*</label>
                    <input name="givenName" class="form-control text-capitalize" formControlName="givenName" placeholder="{{'PersonNameDataDTO_GivenName' | localize}}" autocomplete="off" />
                    <app-reactive-validation-message [reactiveFormControl]="formUserProfile.controls['givenName']" [errorName]='"required"' [reactiveFormVar]="formUserProfileTemplate"></app-reactive-validation-message>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                <div class="form-group">
                    <label>{{'PersonNameDataDTO_Surname' | localize}}*</label>
                    <input name="surname" formControlName="surname" class="form-control text-capitalize" placeholder="{{'PersonNameDataDTO_Surname' | localize}}" autocomplete="off" />
                    <app-reactive-validation-message [reactiveFormControl]="formUserProfile.controls['surname']" [errorName]='"required"' [reactiveFormVar]="formUserProfileTemplate"></app-reactive-validation-message>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                <div class="form-group">
                    <label>{{'PersonNameDataDTO_BirthDate' | localize}}</label>
                    <p-inputMask (onBlur)="validateDate($event, 'birthDate')" formControlName="birthDate" name="birthDatePassenger" mask="99/99/9999" placeholder="{{'PersonNameDataDTO_BirthDate' | localize}}" slotChar="{{formatMask()}}"></p-inputMask>                                    
                    <app-reactive-validation-message [reactiveFormControl]="formUserProfile.controls['birthDate']" [errorName]='"validDate"' [reactiveFormVar]="formUserProfileTemplate"></app-reactive-validation-message>
                </div>
            </div>                            
        </div>
        <div class="row" *ngIf="!showMinimalRegisterForm">
            <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                <label>{{"PersonNameDataDTO_Gender" | localize}}</label>
                <div class="form-group">
                    <mat-radio-group formControlName="gender" name="gender" class="mat-radio-group">
                        <div class="row d-inline-flex">
                            <div class="col-6">
                                <mat-radio-button #mButton (click)="uncheckSameValue($event, mButton)" [checked]='formUserProfile.controls["gender"].value == "m"' value='m' color="primary">{{'Common_male' | localize}}</mat-radio-button>
                            </div>
                            <div class="col-6">
                                <mat-radio-button #fButton (click)="uncheckSameValue($event, fButton)" [checked]='formUserProfile.controls["gender"].value  === "f"' value='f' color="primary">{{'Common_female' | localize}}</mat-radio-button>
                            </div>
                        </div>
                    </mat-radio-group>
                </div>
                <app-reactive-validation-message [reactiveFormControl]="formUserProfile.controls['gender']" [errorName]='"required"' [reactiveFormVar]="formUserProfileTemplate"></app-reactive-validation-message>
            </div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                <div class="form-group">
                    <label>{{'UserProfileDTO_Nationality' | localize}}*</label>
                    <app-countries (selectedCountryChanged)=updateSelectedCountryInModel($event) [codSelectedCountry]="userProfile.nationality" [materialStyle]="false"></app-countries>
                    <app-reactive-validation-message [reactiveFormVar]="formUserProfileTemplate" [reactiveFormControl]="formUserProfile.controls['nationality']" [activateTouched]="true" [errorName]="'required'"></app-reactive-validation-message>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                <div class="form-group">
                    <label>{{'UserProfileDTO_FiscalCode' | localize}}</label>
                    <input name="fiscalCode" class="form-control" formControlName="fiscalCode" placeholder="{{'UserProfileDTO_FiscalCode' | localize}}" autocomplete="off" />
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!showMinimalRegisterForm">
            <div class="col-12">
                <h5 class="d-flex">
                    {{'ReservationDetail_Personal_Info' | localize}}
                </h5>
            </div>
            <div class="form-group col-12 col-sm-12 col-md-4 col-lg-4" *ngIf="!showMinimalRegisterForm || (showMinimalRegisterForm && saveUserProfile)">
                <label>{{'PersonNameDataDTO_Email' | localize}}*</label>
                <input type="email" name="userEmail" class="form-control" formControlName="userEmail" placeholder="{{'PersonNameDataDTO_Email' | localize}}" maxlength="256" userEmail />
                <app-reactive-validation-message [reactiveFormControl]="formUserProfile.controls['userEmail']" [errorName]='"required"' [reactiveFormVar]="formUserProfileTemplate"></app-reactive-validation-message>
                <app-reactive-validation-message [reactiveFormControl]="formUserProfile.controls['userEmail']" [errorName]='"pattern"' [reactiveFormVar]="formUserProfileTemplate"></app-reactive-validation-message>
            </div>
            <ng-container>
                <div class="form-group col-12 col-sm-12 col-md-4 col-lg-4">
                    <label>{{'PersonNameDataDTO_PhoneNumber' | localize}}*</label>
                    <input phoneInput
                            [phoneValue]="formUserProfile.controls['phoneNumber'].value"
                            [ng2TelInputOptions]="{initialCountry: 'it', 'autoPlaceholder': 'off', 'preferredCountries': ['it', 'gb', 'de', 'fr', 'es', 'us']}"
                            (ng2TelOutput)="getPhoneValue($event)"
                            (intlTelInputObject)="getPhoneIstance($event)"
                            (hasError)="hasPhoneError($event, formUserProfile.controls['phoneNumber'])"
                            data-tel-error="validate" type="tel"
                            formControlName="phoneNumber" name="phoneNumber" class="form-control custom-tel" placeholder="{{'PersonNameDataDTO_PhoneNumber' | localize}}" />
                    <app-reactive-validation-message [reactiveFormControl]="formUserProfile.controls['phoneNumber']"
                            [errorName]='"required"' [activateTouched]="true" [reactiveFormVar]="formUserProfileTemplate">
                    </app-reactive-validation-message>
                    <app-reactive-validation-message [reactiveFormControl]="formUserProfile.controls['phoneNumber']" [errorName]='"telInput"' [reactiveFormVar]="formUserProfileTemplate"></app-reactive-validation-message>
                </div>
            </ng-container> 
            <div class="form-group col-12 col-sm-12 col-md-4 col-lg-4">
                <label>{{'PersonNameDataDTO_DocumentMail' | localize}}</label>
                <input type="email" name="documentMail" class="form-control" formControlName="documentMail" placeholder="{{'PersonNameDataDTO_DocumentMail' | localize}}" maxlength="256" />
                <app-reactive-validation-message [reactiveFormControl]="formUserProfile.controls['documentMail']" [errorName]='"pattern"' [reactiveFormVar]="formUserProfileTemplate"></app-reactive-validation-message>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <accordion id="otherInfo">
                    <accordion-group>
                        <a class="btn btn-secondary btn-block" accordion-heading (click)="isShowMoreInformationClosed = !isShowMoreInformationClosed">
                            <ng-container *ngIf="isShowMoreInformationClosed">
                                {{'show_more' | localize}}
                                <i class="material-icons">keyboard_arrow_down</i>
                            </ng-container>
                            <ng-container *ngIf="!isShowMoreInformationClosed">
                                {{'show_less' | localize}}
                                <i class="material-icons">keyboard_arrow_up</i>
                            </ng-container>
                        </a>     
                        <div class="row" formGroupName="address">
                            <div class="col-12">
                                <h5 class="d-flex">
                                    {{'Common_Address' | localize}}
                                </h5>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-4">
                                        <div class="form-group">
                                            <label>{{'Common_Street' | localize}}</label>
                                            <input type="text" formControlName="address1" name="passengerAddress" class="form-control text-capitalize" placeholder="{{'Common_Street' | localize}}" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-4">
                                        <div class="form-group">
                                            <label>{{'Common_City' | localize}}</label>
                                            <input type="text" formControlName="city" name="passengerCity" class="form-control text-capitalize" placeholder="{{'Common_City' | localize}}">
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-4">
                                        <div class="form-group">
                                            <label>{{'Common_ProvinceState' | localize}}</label>
                                            <input type="text" formControlName="province" name="passengerState" class="form-control text-capitalize" placeholder="{{'Common_ProvinceState' | localize}}">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-4">
                                        <div class="form-group">
                                            <label>{{'Common_ZipCode' | localize}}</label>
                                            <input type="text" formControlName="zip" name="passengerZipCode" class="form-control" placeholder="{{'Common_ZipCode' | localize}}">
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-4">
                                        <div class="form-group m-form__group">
                                            <label>{{'Common_Country' | localize}}
                                            </label>
                                            <app-countries (selectedCountryChanged)="updateCountry($event)" [countryList]="countryList" [waitForCountryList]="true" [codSelectedCountry]="userProfile.address.country"></app-countries>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                        <div class="row" *ngIf="!showMinimalRegisterForm">
                            <div class="col-12">
                                <h5 class="d-flex">
                                    {{'UserProfileDTO_SpecialNeeds' | localize}}
                                </h5>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="form-group">
                                    <textarea name="specialNeeds" class="form-control" style="min-height: 40px;" formControlName="specialNeeds" placeholder="{{'UserProfileDTO_SpecialNeeds' | localize}}" rows="2"></textarea>
                                </div>
                            </div>
                        </div>                                        
                        <ng-container *ngIf="!showMinimalRegisterForm">
                            <div class="row kt--margin-top-10">
                                <div class="col-12 sections" formArrayName="travelDocuments">
                                    <div class="row">
                                        <div class="col-12">
                                            <h5 class="d-flex">
                                                {{'TravelDocuments_title' | localize}}
                                                <span class="ml-auto">
                                                    <a href="javascript:;" class="btn-link mb-3 d-flex text-primary add-link" (click)="addTravelDocument()">{{'TravelDocuments_add' | localize}}</a>
                                                </span>
                                            </h5>
                                            <p *ngIf="!formUserProfile?.controls['travelDocuments']?.controls || formUserProfile?.controls['travelDocuments']?.controls.length == 0">{{'TravelDocuments_NoData' | localize}}</p>
                                        </div>
                                    </div>
                                    <ng-container *ngFor="let travelDocument of checkformtravelDocuments(formUserProfile); let i = index">
                                        <div [formGroup]="travelDocument">
                                            <ng-container *ngIf="!modifyTravelDocuments[i]">
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-6">
                                                        <div class="card card-custom kt-portlet--success kt-portlet--head-solid-bg kt-portlet--bordered">
                                                            <div class="card-header">
                                                                <div class="card-title">
                                                                    <div class="card-label">
                                                                        <h6 class="kt-portlet__head-text">{{travelDocument.controls['documentType'].value | documentType}} ({{travelDocument.controls['issueCountry'].value}})</h6>
                                                                    </div>
                                                                </div>
                                                                <div class="card-toolbar">
                                                                    <div data-toggle="buttons">
                                                                        <ng-container>
                                                                            <span class="d-flex">
                                                                                <a href="javascript:;" class="btn-link mr-3" (click)="modifyTravelDocument(i)">{{'Common_edit' | localize}}</a>
                                                                                <a href="javascript:;" class="btn-link text-danger" (click)="removeTravelDocument(i)"> X </a>
                                                                            </span>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card-body">
                                                                <div class="col-12">
                                                                    <div class="row">
                                                                        <div class="col-6">
                                                                            <p>{{travelDocument.controls['documentNumber'].value}}</p>
                                                                            <p>
                                                                                <label> {{'TravelDocumentDto_ExpirationDate' | localize}}: </label>
                                                                                {{travelDocument.controls['expirationDate'].value}}
                                                                            </p>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <span class="float-right badge badge-primary" *ngIf="travelDocument.controls['isDefault'].value === true">{{'Default' | localize}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="modifyTravelDocuments[i]">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="card card-custom kt-portlet--success kt-portlet--head-solid-bg kt-portlet--bordered">
                                                            <div class="card-header">
                                                                <div class="card-title">
                                                                    <div class="card-label">
                                                                        <h5 class="kt-portlet__head-text">{{'TravelDocument_Modify_title' | localize}} {{i+1}}</h5>
                                                                    </div>
                                                                </div>
                                                                <div class="card-toolbar">
                                                                    <div data-toggle="buttons" class="mt-2">
                                                                        <ng-container>
                                                                            <button class="btn btn-default ml-auto" (click)="undoPendingChangesTravelDocument(i)">{{"Common_Cancel" | localize}}</button>
                                                                            <button class="btn btn-primary" (click)="completeAddTravelDocument(i)">
                                                                                {{"Common_Add" | localize}}
                                                                            </button>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card-body ">
                                                                <app-travel-document [index]="i" [travelDocumentGroupInput]="travelDocument" [countryList]="countryList" [formTemplate]="formUserProfileTemplate"></app-travel-document>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!showMinimalRegisterForm">
                            <div class="row kt--margin-top-10">
                                <div class="col-12 sections" formArrayName="fidelityCardPrograms">
                                    <div class="row">
                                        <div class="col-12">
                                            <h5 class="d-flex">
                                                {{'UserProfileDto_FidelityCardPrograms_title' | localize}}
                                                <span class="ml-auto">
                                                    <a href="javascript:;" class="btn-link mb-3 d-flex text-primary add-link" (click)="addFidelityCardProgram()">{{'UserProfileDto_FidelityCardPrograms_add' | localize}}</a>
                                                </span>
                                            </h5>
                                            <p *ngIf="!formUserProfile.get('fidelityCardPrograms')?.controls || formUserProfile.get('fidelityCardPrograms')?.controls.length == 0">{{'UserProfileDto_FidelityCardPrograms_NoData' | localize}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                    <ng-container *ngFor="let fidelityCard of formUserProfile.get('fidelityCardPrograms')?.controls; let i = index">
                                        <div class="col-sm-12 col-md-6" [formGroup]="fidelityCard">
                                            <div class="card card-custom kt-portlet--success kt-portlet--head-solid-bg kt-portlet--bordered">
                                                <div class="card-header">
                                                    <div class="card-title">
                                                        <div class="card-label">
                                                            <h6 class="kt-portlet__head-text">{{"UserProfileDto_FidelityCardPrograms_title" | localize}} {{i+1}}</h6>
                                                        </div>
                                                    </div>
                                                    <div class="card-toolbar">
                                                        <div data-toggle="buttons">
                                                            <ng-container *ngIf="modifyFidelityCardPrograms[i]">
                                                                <button class="btn btn-default ml-auto" (click)="undoPendingChangesFidelityCardProgram(i)">{{"Common_Cancel" | localize}}</button>
                                                                <button class="btn btn-primary" (click)="completeAddFidelityCardProgram(i)">
                                                                    {{"Common_Add" | localize}}
                                                                </button>
                                                            </ng-container>
                                                            <ng-container *ngIf="!modifyFidelityCardPrograms[i]">
                                                                <a href="javascript:;" class="btn-link mr-3" (click)="modifyFidelityCardProgram(i)">{{'Common_edit' | localize}}</a>
                                                                <a href="javascript:;" class="btn-link text-danger" (click)="removeFidelityCardProgram(i)"> X </a>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <a class="btn btn-secondary btn-block" (click)="isFidelityCardOpened[i] = !isFidelityCardOpened[i]">
                                                        {{'UserProfileDto_FidelityCardPrograms_title' | localize}}
                                                        <ng-container *ngIf="isFidelityCardOpened[i]">
                                                            <i class="material-icons">keyboard_arrow_up</i>
                                                        </ng-container>
                                                        <ng-container *ngIf="!isFidelityCardOpened[i]">
                                                            <i class="material-icons">keyboard_arrow_down</i>
                                                        </ng-container>
                                                    </a>
                                                    <accordion class="accordion-heading-hide" id="frequent_flyer_panel">
                                                        <accordion-group [isOpen]="isFidelityCardOpened[i]">
                                                            <div class="form-group">
                                                                <p-dropdown class="custom-dropdown" [options]="fidelityItemList"
                                                                            [showClear]="modifyFidelityCardPrograms[i]" formControlName="programId"
                                                                            name="programId" [style]="{'width':'100%'}" [resetFilterOnHide]="true"
                                                                            filter="true" [readonly]="!modifyFidelityCardPrograms[i]">
                                                                    <ng-template let-item pTemplate="selectedItem">
                                                                        <img [src]="getLogoFidelityCard(item.styleClass, item.value)" class="frequentFlyer__Logo">
                                                                        <span class="align-middle" [innerHTML]="item.label"></span>
                                                                    </ng-template>
                                                                    <ng-template let-companyCode pTemplate="item">
                                                                        <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                                                                            <img [src]="getLogoFidelityCard(companyCode.styleClass, companyCode.value)" class="frequentFlyer__Logo">
                                                                            <div style="font-size:14px;float:right;margin-top:4px" [innerHTML]="companyCode.label"></div>
                                                                        </div>
                                                                    </ng-template>
                                                                </p-dropdown>
                                                                <app-reactive-validation-message [reactiveFormControl]="fidelityCard.controls['programId']" [errorName]='"required"' [activateTouched]="true" [reactiveFormVar]="formUserProfileTemplate"></app-reactive-validation-message>
                                                            </div>
                                                            <div class="form-group">
                                                                <input type="text" formControlName="membershipId" name="membershipId" class="form-control text-capitalize" placeholder="{{'Flight_FrequentFlyer_FrequentFlyerMembershipId' | localize}}" [readonly]="!modifyFidelityCardPrograms[i]">
                                                                <app-reactive-validation-message [reactiveFormControl]="fidelityCard.controls['membershipId']" [errorName]='"required"' [activateTouched]="true" [reactiveFormVar]="formUserProfileTemplate"></app-reactive-validation-message>
                                                            </div>
                                                            <span class="font-weight-light">{{'UserProfileDto_FidelityCardPrograms_disclaimer' | localize}}</span>
                                                        </accordion-group>
                                                    </accordion>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!showMinimalRegisterForm">
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{'UserProfileDto_EmergencyContact_title' | localize}}</h5>
                                    <div class="row" formGroupName="emergencyContact">
                                        <div class="form-group col-12 col-sm-12 col-md-4 col-lg-4">
                                            <label>{{'UserProfileDto_EmergencyContact_Name' | localize}}</label>
                                            <input name="name" formControlName="name" class="form-control text-capitalize" placeholder="{{'UserProfileDto_EmergencyContact_Name' | localize}}" autocomplete="off" />
                                        </div>
                                        <div class="form-group col-12 col-sm-12 col-md-4 col-lg-4">
                                            <label>{{'UserProfileDto_EmergencyContact_Surname' | localize}}</label>
                                            <input name="surname" formControlName="surname" class="form-control text-capitalize" placeholder="{{'UserProfileDto_EmergencyContact_Surname' | localize}}" autocomplete="off" />
                                        </div>
                                        <div class="form-group col-12 col-sm-12 col-md-4 col-lg-4">
                                            <label>{{'UserProfileDto_EmergencyContact_PhoneNumber' | localize}}</label>
                                            <input type="tel" phoneInput [ng2TelInputOptions]="{initialCountry: 'it', 'autoPlaceholder': 'off', 'preferredCountries': ['it', 'gb', 'de', 'fr', 'es', 'us']}"
                                                [phoneValue]="this.checkformHotelphoneNumber(formUserProfile).value"
                                                (ng2TelOutput)="getEmergencyPhoneValue($event)"
                                                (intlTelInputObject)="getEmergencyPhoneIstance($event)"
                                                (hasError)="hasPhoneError($event, this.checkformHotelphoneNumber(formUserProfile))"
                                                data-tel-error="validate" formControlName="phoneNumber" name="phoneNumber"
                                                class="form-control custom-tel" placeholder="{{'UserProfileDto_EmergencyContact_PhoneNumber' | localize}}" />
                                            <app-reactive-validation-message [reactiveFormControl]="this.checkformHotelphoneNumber(formUserProfile)" [errorName]='"telInput"' [reactiveFormVar]="formUserProfileTemplate"></app-reactive-validation-message>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!showMinimalRegisterForm && isGranted('Pages.Trips.CenterOfCosts')">
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{'UserProfileDto_Siap_title' | localize}}</h5>
                                    <div class="row">
                                        <div class="col-12 col-sm-12 col-md-4">
                                            <label>{{'UserProfileDto_CompanyHQ_Name' | localize}}</label>
                                            <input #companyHQInput name="companyHQInput" name="selectedCompanyHQ" class="traveler__input form-control kt-input" type="text"
                                                formControlName="companyHeadquartersId"
                                                [matAutocomplete]="companyHQAutocomplete" (click)="companyHQInput.select()" placeholder="{{'CompanyHQ_Select' | localize}}"
                                                spellcheck="false" autocomplete="off" autocorrect="off" autocapitalize="off">
                                            <mat-autocomplete #companyHQAutocomplete="matAutocomplete" [displayWith]="displaySelectedCompanyHQText(companyHQs)" (optionSelected)="onCompanyHqSelected($event)">
                                                <ng-container *ngIf="companyHQs">
                                                    <mat-option *ngFor="let hq of companyHQs | keyvalue" [value]="hq.key" class="cocOption">
                                                        <div class="cocOptionTextContainer">
                                                            <div class="cocInfoText">
                                                                <b>{{hq.value.displayName}}</b>
                                                                <span>{{hq.value.codeUnit}}</span>
                                                            </div>
                                                        </div>
                                                    </mat-option>
                                                </ng-container>
                                            </mat-autocomplete>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-4">
                                            <label>{{'UserProfileDto_Siap_CenterOfCosts_Name' | localize}}</label>
                                            <input #cocInput name="cocInput" name="selectedCenterOfCosts" class="traveler__input form-control kt-input" type="text"
                                                formControlName="centerOfCostsId"
                                                [matAutocomplete]="cocAutocomplete" (click)="cocInput.select()" placeholder="{{'CenterOfCost_Select' | localize}}"
                                                spellcheck="false" autocomplete="off" autocorrect="off" autocapitalize="off">
                                            <mat-autocomplete #cocAutocomplete="matAutocomplete" [displayWith]="displaySelectedCocText(centersOfCosts)">
                                                <ng-container *ngIf="centersOfCosts">
                                                    <mat-option *ngFor="let coc of centersOfCosts | keyvalue" [value]="coc.key" class="cocOption">
                                                        <div class="cocOptionTextContainer">
                                                            <div class="cocInfoText">
                                                                <b>{{coc.value.displayName}}</b>
                                                                <span>{{coc.value.codeUnit}}</span>
                                                            </div>
                                                        </div>
                                                    </mat-option>
                                                </ng-container>
                                            </mat-autocomplete>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-4">
                                            <div class="form-group">
                                                <label>{{'UserProfileDto_Siap_WorkId_Name' | localize}}</label>
                                                <input name="workId" class="form-control text-capitalize" formControlName="workId" placeholder="{{'UserProfileDto_Siap_WorkId_Name' | localize}}" autocomplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="fieldConfig && !showMinimalRegisterForm">
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{'Common_AdditionalInformation' | localize}}</h5>
                                    <app-custom-field-form [fields]="fieldConfig" [submitEvent]="customFieldSubmit.asObservable()" (submit)="onCustomFieldSubmit($event)" [lazyAutocomplete]="true"></app-custom-field-form>    
                                </div>
                            </div>                                            
                        </ng-container>
                    </accordion-group>
                </accordion>
            </div>
        </div>                        
        <div class="d-flex kt--margin-top-10">
            <div class="ml-auto">
                <button *ngIf="showCancelButton" type="button" class="btn btn-secondary close-button" (click)="close()">{{"Cancel" | localize}}</button>
                <button type="submit" class="btn btn-primary" (click)="save()" [buttonBusy]="saving" busyText="{{'SavingWithThreeDot' | localize}}" [disabled]="!vatNumberFormatConfirmed">
                    <i class="fa fa-save"></i>
                    {{"Common_Save_changes" | localize}}
                </button>
            </div>
        </div>
    </form>
</ng-template>