import { AppComponentBase } from 'shared/common/app-component-base';
import { AirBookResponseDTO } from '@shared/service-proxies/service-proxies';
import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { KeyValuePair } from '@app/shared/models/ClientFlightModels';
import { AirBookResponseDto } from '@app/api/models';

@Injectable()
export class CheckoutService extends AppComponentBase {
    private airbookResponse;
    companyCodeList: KeyValuePair<string, string>[];
    trainCompanyCodeList: KeyValuePair<string, string>[];
    hotelCompanyCodeList: KeyValuePair<string, string>[];
    creditCardList: KeyValuePair<string, string>[];

    constructor(injector: Injector) {
        super(injector);
        this.createCompaniesCodeList();
        this.createCreditCardType();
    }

    public setAirbookApiResponse(airbookResponse: AirBookResponseDto) {
        this.airbookResponse = airbookResponse;
    }
    public setAirbookResponse(airbookResponse: AirBookResponseDTO) {
        this.airbookResponse = airbookResponse;
    }

    public getAirbookResponse(): Observable<any> {
        return of(this.airbookResponse);
    }

    createCreditCardType() {
        this.creditCardList = [
            new KeyValuePair<string, string>('CA', 'MasterCard'),
            new KeyValuePair<string, string>('VI', 'Visa'),
            new KeyValuePair<string, string>('AX', 'AmericanExpress'),
            new KeyValuePair<string, string>('TP', 'AirPlus')
        ];
    }

    createCompaniesCodeList() {
        this.trainCompanyCodeList = [
            new KeyValuePair<string, string>(null, this.l('Flight_FrequentFlyer_select')),
            new KeyValuePair<string, string>('7T', this.l('CARTAFRECCIA')),
            new KeyValuePair<string, string>('2Q', this.l('CARTAVIAGGIO_ITALO')),
        ];
        this.hotelCompanyCodeList = [
            new KeyValuePair<string, string>('TE', 'Holtel Fidelity card')
        ];
        this.companyCodeList = [
            new KeyValuePair<string, string>(null, this.l('Flight_FrequentFlyer_select')),
            new KeyValuePair<string, string>('AZ', 'Ita Volare'),
            new KeyValuePair<string, string>('EK', 'Emirates Skywards'),
            new KeyValuePair<string, string>('SU', 'Aeroflot Bonus'),
            new KeyValuePair<string, string>('AM', 'Aeromexico Club Premier'),
            new KeyValuePair<string, string>('AH', 'Air Algerie Plus'),
            new KeyValuePair<string, string>('UU', 'Air Austral Capricorne'),
            new KeyValuePair<string, string>('R7', 'Aserca Airlines Privilege'),
            new KeyValuePair<string, string>('BA', 'British Airways Executive Club'),
            new KeyValuePair<string, string>('FB', 'Bulgaria Air FlyFB Bonus Points'),
            new KeyValuePair<string, string>('CM', 'Copa Airlines ConnectMiles'),
            new KeyValuePair<string, string>('EY', 'Etihad Guest Miles'),
            new KeyValuePair<string, string>('G3', 'GOL Smiles'),
            new KeyValuePair<string, string>('GF', 'Gulf Air FalconFlyer'),
            new KeyValuePair<string, string>('HX', 'Hong Kong Airlines Fortune Wings Club'),
            new KeyValuePair<string, string>('TM', 'LAM Mozambique Airlines Flamingo Club'),
            new KeyValuePair<string, string>('IG', 'Meridiana Hi-Fly'),
            new KeyValuePair<string, string>('YM', 'Montenegro Airlines Vision Team'),
            new KeyValuePair<string, string>('PR', 'Philipine Mabuhay Miles'),
            new KeyValuePair<string, string>('TP', 'TAP Portugal Victoria'),
            new KeyValuePair<string, string>('TU', 'Tunis Air Fidelys'),
            new KeyValuePair<string, string>('U6', 'Ural Airlines Travelling Club "Wings"'),
            new KeyValuePair<string, string>('MF', 'Xiamen Egret Card'),
            new KeyValuePair<string, string>('JP', 'Adria Miles &amp; More'),
            new KeyValuePair<string, string>('A3', 'Aegean Miles &amp; Bonus'),
            new KeyValuePair<string, string>('EI', 'Aer Lingus Gold Circle Club'),
            new KeyValuePair<string, string>('AR', 'Aerolineas &nbsp; Argentinas &nbsp; Plus'),
            new KeyValuePair<string, string>('ZI', 'Aigle Azur Plus'),
            new KeyValuePair<string, string>('KC', 'Air Astana Nomad Club'),
            new KeyValuePair<string, string>('AC', 'Air Canada Aeroplan'),
            new KeyValuePair<string, string>('CA', 'Air China PhoenixMiles'),
            new KeyValuePair<string, string>('CI', 'China Airlines'),
            new KeyValuePair<string, string>('EN', 'Air Dolomiti Miles &amp; More'),
            new KeyValuePair<string, string>('UX', 'Air Europa Flying Blue'),
            new KeyValuePair<string, string>('AF', 'Air France Flying Blue'),
            new KeyValuePair<string, string>('AI', 'Air India Flying Returns'),
            new KeyValuePair<string, string>('MD', 'Air Madagascar Namako'),
            new KeyValuePair<string, string>('SW', 'Air Namibia Reward$'),
            new KeyValuePair<string, string>('NZ', 'Air New Zealand Air Points'),
            new KeyValuePair<string, string>('YW', 'Air Nostrum Iberia Plus'),
            new KeyValuePair<string, string>('BT', 'airBaltic Miles'),
            new KeyValuePair<string, string>('AB', 'airberlin topbonus'),
            new KeyValuePair<string, string>('SB', 'Aircalin Flying Blue'),
            new KeyValuePair<string, string>('NH', 'All Nippon Mileage Club'),
            new KeyValuePair<string, string>('AA', 'American AAdvantage'),
            new KeyValuePair<string, string>('OZ', 'Asiana Club'),
            new KeyValuePair<string, string>('OS', 'Austrian Miles &amp; More'),
            new KeyValuePair<string, string>('CI', 'China Airlines Dynasty Flyer'),
            new KeyValuePair<string, string>('DE', 'Condor Miles &amp; More'),
            new KeyValuePair<string, string>('OU', 'Croatia Miles &amp; More'),
            new KeyValuePair<string, string>('OK', 'Czech OK Plus'),
            new KeyValuePair<string, string>('DL', 'Delta SkyMiles'),
            new KeyValuePair<string, string>('MS', 'EgyptAir Plus'),
            new KeyValuePair<string, string>('LY', 'El Al Matmid Club'),
            new KeyValuePair<string, string>('ET', 'Ethiopian Sheba Miles'),
            new KeyValuePair<string, string>('BR', 'EVA Air Infinity MileageLands'),
            new KeyValuePair<string, string>('AY', 'Finnair Plus'),
            new KeyValuePair<string, string>('BE', 'Flybe Rewards 4 All'),
            new KeyValuePair<string, string>('GA', 'Garuda Indonesia'),
            new KeyValuePair<string, string>('HU', 'Hainan Fortune Wings Club'),
            new KeyValuePair<string, string>('IB', 'Iberia Plus'),
            new KeyValuePair<string, string>('FI', 'Icelandair Saga Club'),
            new KeyValuePair<string, string>('ME', 'Middle East'),
            new KeyValuePair<string, string>('JU', 'Jat Airways Extra Flight'),
            new KeyValuePair<string, string>('9W', 'Jet Privilege'),
            new KeyValuePair<string, string>('KQ', 'Kenya Airways Flying Blue'),
            new KeyValuePair<string, string>('KL', 'KLM Flying Blue'),
            new KeyValuePair<string, string>('KE', 'Korean Air Lines'),
            new KeyValuePair<string, string>('KU', 'Kuwait Airways Oasis Club'),
            new KeyValuePair<string, string>('LH', 'Lufthansa Miles and More'),
            new KeyValuePair<string, string>('WY', 'Oman Air Sinbad'),
            new KeyValuePair<string, string>('QF', 'Qantas Frequent Flyer'),
            new KeyValuePair<string, string>('AT', 'Royal Air Maroc Safar Flyer'),
            new KeyValuePair<string, string>('RJ', 'Royal Jordanian Royal Plus'),
            new KeyValuePair<string, string>('S7', 'S7 Priority'),
            new KeyValuePair<string, string>('SV', 'Saudi Airlines'),
            new KeyValuePair<string, string>('SK', 'SAS EuroBonus'),
            new KeyValuePair<string, string>('S4', 'SATA Imagine'),
            new KeyValuePair<string, string>('SQ', 'Singapore Airlines KrisFlyer'),
            new KeyValuePair<string, string>('UL', 'SriLankan FlySmiLes'),
            new KeyValuePair<string, string>('LX', 'Swiss Miles &amp; More'),
            new KeyValuePair<string, string>('JJ', 'TAM Airlines(JJ) Fidelidade TAM'),
            new KeyValuePair<string, string>('RO', 'Tarom Smart Miles'),
            new KeyValuePair<string, string>('TG', 'Thai Royal Orchid Plus'),
            new KeyValuePair<string, string>('TK', 'Turkish Airlines Miles &amp; Smiles'),
            new KeyValuePair<string, string>('T7', 'Twin Jet Flying Blue'),
            new KeyValuePair<string, string>('PS', 'Ukraine International Panorama Club'),
            new KeyValuePair<string, string>('VY', 'Vueling Punto')
        ];
    }
}
