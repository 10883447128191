import {
    Directive,
    Input,
    ComponentFactoryResolver,
    ViewContainerRef,
    OnInit,
} from '@angular/core';
import { TagTypeDto } from '@shared/service-proxies/service-proxies';
import { UntypedFormGroup } from '@angular/forms';
import { SelectComponent } from './select/select.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { TextareaComponent } from './textarea/textarea.component';
import { InputComponent } from './input/input.component';
import { DatetimepickerComponent } from './datetimepicker/datetimepicker.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { ButtonComponent } from './button/button.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { FieldConfigDto, UserProfileDto } from '@app/api/models';
import { AutocompleteLazyComponent } from './autocomplete/autocomplete-lazy/autocomplete-lazy.component';
import { AutocompleteRemoteComponent } from './autocomplete/autocomplete-remote/autocomplete-remote.component';

@Directive({
    selector: '[dynamicField]',
})
export class DynamicFieldDirective implements OnInit {
    @Input() field: FieldConfigDto;
    @Input() group: UntypedFormGroup;
    @Input() readonly: boolean;
    @Input() userProfiles: Array<UserProfileDto>;
    @Input() lazyAutocomplete: boolean = false;
    componentRef;

    constructor(
        private resolver: ComponentFactoryResolver,
        private container: ViewContainerRef
    ) {}

    ngOnInit(): void {
        const component = this.getComponent(this.field.type);
        const factory = this.resolver.resolveComponentFactory(component);
        this.componentRef = this.container.createComponent(factory);
        this.componentRef.instance.field = this.field;
        this.componentRef.instance.group = this.group;
        this.componentRef.instance.readonly = this.readonly;
        this.componentRef.instance.userProfiles = this.userProfiles;
        this.componentRef.instance.updateState();
    }

    getComponent(type: TagTypeDto) {
        switch (type) {
            case TagTypeDto.Select: return SelectComponent;
            case TagTypeDto.CheckBox: return CheckboxComponent;
            case TagTypeDto.RadioButton: return RadioButtonComponent;
            case TagTypeDto.TextArea: return TextareaComponent;
            case TagTypeDto.Input: return InputComponent;
            case TagTypeDto.DatePicker: return DatepickerComponent;
            case TagTypeDto.DateTimePicker: return DatetimepickerComponent;
            case TagTypeDto.Button: return ButtonComponent;
            case TagTypeDto.MultiSelect: return MultiselectComponent;
            case TagTypeDto.Autocomplete: return this.lazyAutocomplete ? AutocompleteLazyComponent : AutocompleteRemoteComponent;
        }
    }
}
